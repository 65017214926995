import { bus } from '../../../../../main';
const AgentMixin = {
    methods:{
        /**@param {number} amount */
        credit_agent(amount){
            this.agent["wallet"].deposit(amount);
            bus.$emit("debit-offtaker",amount);
        },
        /**
         * @param {number} amount
         */
         debit_agent(amount){
            this.agent["wallet"].withdraw(amount);
            bus.$emit("credit-offtaker",amount);
        },
    }
};

export {AgentMixin}