<template>
    <div class="mt-3">
        <h6>Registered Village Agents</h6>
        <b-table :fields="fields" :items="agents" show-empty>
            <template #empty>
                No Village Agents at this time
            </template>
            <template #cell(va_name)="data">
                <span class="agent-name text-capitalize">{{data.item.va_name}}</span>
            </template>
        </b-table>
    </div>
</template>
<style scoped>
.agent-name{
    display: inline-block;
    width:10ch;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}
</style>
<script>
import { axiosApi } from '../..'
import {VAS_URL} from "."
import { bus } from '../../../../../../main'
export default {
    data(){
        return {
            fields:[
                {key:"va_name",label:"Name"},
                {key:"va_phonenumber",label:"Phone"}
            ],
            agents:[]
        }
    },
    mounted(){
        bus.$on("va-created",(va)=>{
            this.agents.push(va)
        });
        this.list_vas();
    },
    methods:{
        async list_vas(){
            const res = await axiosApi({
                url:VAS_URL(this.$route.params.agent_id)
            });
            this.agents = res.data;
        }
    }
}
</script>