<template>
  <div>
    <!-- Payment history, chart-->
    <div class="d-flex align-items-center" v-if="agent">
      <div class="col-6">
        <b-card class="mt-4" sub-title="Agent Profile">
          <div class="d-flex">
            <div class="col-8">
              <b-card-text class="mb-3">{{ agent.name }}</b-card-text>
              <h6>{{ agent.username }}</h6>
              <h6 class="mb-1">{{ agent.phone_no }}</h6>
              <b-button
                variant="success"
                @click="
                  () => {
                    $bvModal.show('credit-agent');
                  }
                "
              >
                <b-icon-plus></b-icon-plus> Credit Wallet
              </b-button>
              &nbsp;
              <b-button variant="light" @click="()=>{
                  $bvModal.show('debit-agent')
                }">
                <b-icon-dash></b-icon-dash> Debit Wallet
              </b-button>
            </div>
            <div class="col-4">
              <span class="text-muted">BALANCE</span>
              <h4 class="font-weight-bold">
                UGX {{ agent.wallet.balance.toLocaleString() }}
              </h4>
            </div>
          </div>
        </b-card>
      </div>
      <div class="col-6">
        <b-tabs content-class="mt-3">
          <b-tab title="Produce (UGX)">
            <produce-amout></produce-amout>
          </b-tab>
          <b-tab title="Produce (Tonnes)" lazy>
            <produce-quantity></produce-quantity>
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <!-- Produce qty bulked chart-->
    <div class="d-flex pt-4">
      <!-- Agents Widget -->
      <div class="col-6">
        <h5 class="border-bottom mb-1 text-center">Village Agents</h5>
        <div class="d-flex">
          <div class="col-6">
            <create-va></create-va>
          </div>
          <div class="col-6">
            <va-list></va-list>
          </div>
        </div>
      </div>
      <!-- Transactions -->
      <div class="col-6">
        <h5 class="border-bottom mb-2 text-center">Transaction Timeline</h5>
        <agent-transactions
          :agent="agent"
          v-if="agent"
          class="w-100"
        ></agent-transactions>
      </div>
    </div>
    <credit-agent
      :agent="agent"
      @credit-agent="credit_agent"
      v-if="agent"
    ></credit-agent>
    <debit-agent 
      :agent="agent" 
      v-if="agent"
      @debit-agent="debit_agent">
    </debit-agent>
  </div>
</template>
<style scoped>
.w-35 {
  width: 35%;
}
</style>
<script>
import { axiosApi, BUYING_AGENT_URL } from "..";
import { Wallet } from "../accounting";
import CreateVa from "./village_agents/CreateVa.vue";
import VaList from "./village_agents/VaList.vue";
import AgentTransactions from "./AgentTransactions.vue";
import CreditAgent from "./CreditAgent.vue";
import DebitAgent from "./DebitAgent.vue";
import ProduceAmout from "./produce/ProduceAmout.vue";
import ProduceQuantity from "./produce/ProduceQuantity.vue";
import { AgentMixin } from "./mixins";
export default {
  components: {
    VaList,
    CreateVa,
    AgentTransactions,
    ProduceAmout,
    ProduceQuantity,
    CreditAgent,
    DebitAgent
  },
  mixins: [AgentMixin],
  computed:{
    agent:{
      get(){
        return this.$store.state.buyer;
      },
      set(agent){
        this.$store.commit("save_agent",agent);
      }
    }
  },
  mounted() {
    if(!this.agent){
      this.get_agent()
    }
  },
  methods: {
    async get_agent() {
      const res = await axiosApi({
        url: BUYING_AGENT_URL + "/" + this.$route.params.agent_id,
      });
      const agent = res.data;
      const wallet = agent["wallet"];
      agent["wallet"] = new Wallet(wallet.id, wallet.balance);
      this.agent = agent;
    }
  },
};
</script>