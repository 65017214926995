import { BUYER_URL, std_fig } from '../..'

const AmountOptions = {
    chart: {
        height: 200,
        type: 'area',
        zoom: {
            enabled: false
        }
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        categories: [],
    },
    tooltip: {
        enabled: true,
        y: {
            formatter: (value) => ' ' + std_fig(value)
        }
    }
}
const AmountSeries = [{
    name: "Amount (UGX)",
    data: []
}];
const QuantitySeries = [{
    name: 'Quantity (T)',
    data: []
}];
const QuantityOptions = {
    chart: {
        height: 200,
        type: 'line',
        zoom: {
            enabled: false
        },
    },
    dataLabels: {
        enabled: false
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        categories: [],
    }
};
const BUYER_PRODUCE = (agent_id) => BUYER_URL(agent_id) + "/" + "produce"
export { AmountOptions, AmountSeries, BUYER_PRODUCE, QuantityOptions, QuantitySeries }