class Wallet{
    /**
     * 
     * @param {string} id 
     * @param {number} balance 
     */
    constructor(id,balance){
        this.id = id;
        this.balance = balance;
    }
    /**
     * 
     * @param {number} amount
     * @returns {void}
     */
    deposit(amount){
        this.balance+=amount;
    }
    withdraw(amount){
        this.balance-=amount;
    }
}
export default Wallet