<template>
  <div class="overflow-auto">
    <b-table
      empty-text="No transactions at this time"
      :fields="fields"
      :items="tranx"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #cell(meta)="data">
        <transaction-display :transaction="data.item"></transaction-display>
      </template>
      <template #cell(amount)="data">
        <span
          :class="{
            'text-success': data.item.recep == agent.wallet_id,
            'text-danger': data.item.donor == agent.wallet_id,
          }"
        >
          <span v-show="data.item.donor == agent.wallet_id">-</span>
          <span v-show="data.item.recep == agent.wallet_id">+</span>
          {{ std_fig(data.item.amount) }}
        </span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="tranx.length"
      :per-page="perPage"
    ></b-pagination>
    <download-excel
      class="btn btn-primary btn-block"
      :data="items"
      type="csv"
      :name="agent.username+'_transactions.csv'"
    >
      <b-icon icon="download" aria-hidden="true"></b-icon> Download Transaction History
    </download-excel>
  </div>
</template>
<style scoped>
.clickable:hover {
  cursor: pointer;
}
</style>
<script>
import { axiosApi, BUYING_AGENT_URL, std_fig } from "..";
import { to_json, Transaction, TransactionDisplay } from "./transactions";
export default {
  props: ["agent"],
  components: { TransactionDisplay },
  data() {
    return {
      perPage: 6,
      currentPage: 1,
      fields: [{ key: "meta", label: "Notes" }, "date", "amount"],
      tranx: [],
      items: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.get_transactions();
    }, 4000);
  },
  methods: {
    async get_transactions() {
      const res = await axiosApi({
        url:
          BUYING_AGENT_URL +
          "/" +
          this.$route.params.agent_id +
          "/transactions",
      });

      const tranx = res.data.map((el) => {
        return new Transaction(el);
      });
      this.tranx = tranx;
      this.items = to_json(tranx, this.agent.wallet_id);
    },
    std_fig,
  },
};
</script>