<template>
  <div>
    <h6 class="mt-3 text-underline">Register Village Agent</h6>
    <b-form v-show="!va_found" @submit.prevent="find_va">
      <b-input-group prepend="Tel." class="mt-1">
        <b-form-input type="tel" v-model="phone_no"></b-form-input>
        <b-input-group-append class="ml-1">
          <b-button variant="success" type="submit">Find</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form>
    <b-card title="Village Agent" :sub-title="agent.va_name" v-show="va_found">
        <b-card-text>
            <b-icon-phone></b-icon-phone> {{agent.va_phonenumber}}, <b-icon-map></b-icon-map> {{agent.va_district}}
        </b-card-text>
        <b-button variant="primary" class="w-100" @click="create_va"><b-icon-plus></b-icon-plus> Agent </b-button>
    </b-card>
  </div>
</template>
<script>
import { VAS_URL } from '.';
import { axiosApi } from '../..';
import { bus } from '../../../../../../main';
/**
 * @param {{va_phonenumber:string,va_name:string,vaId:string}} agent
 */
function reset_agent(agent){
    agent.va_phonenumber = "";
    agent.va_name = "";
    agent.vaId = "";
}
export default {
    data(){
        return {
            va_found:false,
            phone_no:"",
            agent:{va_phonenumber:"",va_name:"",vaId:""}
        }
    },
    methods:{
        async find_va(){
            const res = await axiosApi({
                url:process.env.VUE_APP_BACKEND+process.env.VUE_APP_VA_PFX,
                params:{phone_no:this.phone_no}
            });
            this.agent = res.data.data[0];
            if(this.agent.va_name){
                this.va_found = true;
            }
        },
        async create_va(){
            const form  = new FormData();
            form.set("vaId",this.agent.vaId);
            try{
                const res = await axiosApi({
                    url:VAS_URL(this.$route.params.agent_id),
                    method:"post",
                    data:form
                });
                bus.$emit("va-created",res.data);
                reset_agent(this.agent);
                this.va_found = false;
                return res.data;
            }catch(err){
                if(err.response.status==409){
                    const va = err.response.data;
                    const msg = `Would you like to switch buyer for ${va.va_name}`;
                    this.$dialog.confirm({
                        title:"",
                        body:msg
                    }).then(()=>{
                        const fm = new FormData();
                        fm.set("vaId",va.vaId);
                        axiosApi({
                            url:VAS_URL(this.$route.params.agent_id),
                            method:"put",
                            data:fm
                        }).then((resp)=>{
                            bus.$emit("va-created",resp.data);
                            this.va_found = false;
                            reset_agent(this.agent);
                            return resp.data;
                        });
                    }).catch(()=>{
                        this.va_found=false;
                        reset_agent(this.agent);
                    });
                    // this.$bvToast.toast(err.response.data,{
                    //     title:"Conflict in  Data",
                    //     toaster:'b-toaster-bottom-center',
                    //     variant:'danger',
                    //     solid:true
                    // })
                }
            }

        }
    }
};
</script>