<template>
  <b-modal id="debit-agent" size="md" title="Debit Agent" hide-footer>
    <i>Type amount followed by clicking approve to debit {{agent.name}}</i>
    <b-form @submit.prevent="debit_agent">
      <b-input-group prepend="Amount in UGX" class="mt-3">
        <b-form-input
          type="number"
          v-model="amount"
          :min="0"
          :max="agent.wallet.balance"
          required
          class="mr-1"
        ></b-form-input>
        <b-input-group-append>
          <b-button variant="success ml-1" type="submit"
            >
            <b-spinner v-if="busy" small type="grow"></b-spinner>
            Approve</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </b-modal>
</template>
<script>
import { axiosApi, BUYING_AGENT_URL } from '..';
export default {
    props:["agent"],
    data(){
        return {
            amount:0,
            busy:false
        }
    },
    methods:{
        async debit_agent(){
            const form = new FormData();
            const amount = parseInt(this.amount);
            form.set("amount",amount);
            form.set("wallet_id",this.agent.wallet_id);
            this.busy=true;
            const res = await axiosApi({
                url:BUYING_AGENT_URL+"/"+this.agent.id+"/:debit",
                method:"post",
                data:form
            });
            this.$emit("debit-agent",amount);
            this.busy=false;
            setTimeout(()=>{this.$bvModal.hide("debit-agent")},2000);
            return res.data;
        }
    }
};
</script>