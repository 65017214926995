<template>
    <b-overlay spinner-variant="primary" variant="light" :show="show">
        <apexchart type="area" height="200" :options="chartOptions" :series="series"></apexchart>
    </b-overlay>  
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import {QuantityOptions,QuantitySeries, BUYER_PRODUCE} from "."
import { axiosApi } from '../..'
import { pluck } from '../../../../../utils'
export default {
    components:{
        apexchart:VueApexCharts
    },
    data(){
        return {
            series:QuantitySeries,
            chartOptions:QuantityOptions,
            show:true,
        }
    },
    mounted(){
        this.get_quantity()
    },
    methods:{
        async get_quantity(){
            const res = await axiosApi({url:BUYER_PRODUCE(this.$route.params.agent_id)+"/quantity"})
            const results = res.data;
            this.series = [{...QuantitySeries[0],data:results.map(pluck("quantity"))}]
            const labels = results.map(pluck("date"))
            this.chartOptions = { ...QuantityOptions, xaxis: { categories: labels,type:"datetime" } }
            this.show=false;
        }
    }
}
</script>