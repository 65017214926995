<template>
    <b-overlay spinner-variant="primary" variant="light" :show="show">
        <apexchart type="area" height="200" :options="chartOptions" :series="series"></apexchart>
    </b-overlay>
</template>
<script>
import VueApexCharts from "vue-apexcharts"
import {AmountOptions,AmountSeries, BUYER_PRODUCE} from "."
import { axiosApi } from '../..'
import { pluck } from '../../../../../utils'
export default {
    components:{
        apexchart:VueApexCharts
    },
    data(){
        return {
            show:true,
            series:AmountSeries,
            chartOptions:AmountOptions
        }
    },
    mounted(){
        this.get_amount();
    },
    methods:{
        async get_amount(){
            const res = await axiosApi({url:BUYER_PRODUCE(this.$route.params.agent_id)+"/amount"});
            /**const Array<{amount:number,date:string}> */
            const results = res.data;
            this.series = [{...AmountSeries[0],data:results.map(pluck("amount"))}];
            const labels = results.map(pluck("date"));
            this.chartOptions = { ...AmountOptions, xaxis: { categories: labels,type:"datetime" } }
            this.show = false;
        }
    }
}
</script>